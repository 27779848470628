import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { FaBars, FaMinus } from 'react-icons/fa'
import '../styles/common.scss'
import Links from '../components/Links'
import isoLogo from '../assets/isologo.png'
import Contact from './Contact'

const Menu = () => {

	const [showMobileMenu, setShowMobileMenu] = useState(false)

	const location = useLocation()

	const classForPath = (path) => {
		return location.pathname.indexOf(path) === 0 ? 'selected' : ''
	}

	const toggleMenu = () => {
		setShowMobileMenu(!showMobileMenu)
	}

	return (
		<div className='menu'>
			<div className='isologo'>
				<Link to='/' onClick={() => setShowMobileMenu(false)}>
					<img src={isoLogo} alt="Brujuléate" />
				</Link>
			</div>
			<div className='switch'>
				{!showMobileMenu && <FaBars size={24} onClick={toggleMenu} />}
				{showMobileMenu && <FaMinus size={24} onClick={toggleMenu} />}
			</div>
			<ul className={showMobileMenu ? 'showing' : 'default'}>
				<li>
					<Link to='/acompano' className={classForPath('/acompano')} onClick={toggleMenu}>
						Te acompaño
					</Link>
				</li>
				<li>
					<Link to='/experimenta' className={classForPath('/experimenta')} onClick={toggleMenu}>
						Experimenta
					</Link>
				</li>
				<li>
					<Link to='/videos' className={classForPath('/videos')} onClick={toggleMenu}>
						Videos
					</Link>
				</li>
				<li>
					<Link to='/bio' className={classForPath('/bio')} onClick={toggleMenu}>
						Bio
					</Link>
				</li>
			</ul>
			<div className='links'>
				<Links />
			</div>
			{location.pathname.indexOf('/contacto') > 0 &&
				<Contact />
			}
		</div>
	)
}

export default Menu