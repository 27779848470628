import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import background from '../assets/backgrounds/acompano.jpg'
import Styles from '../styles/index'
import '../styles/section.scss'

const Acompano = () => {

	const location = useLocation()

	return (
		<div className='section'>
			<h1>Te acompaño</h1>
			<div>
				<img src={background} className='background' alt='Juan te acompaña' />
				<div className='content'>
					<p>Si por un instante te permites parar y observarte con atención y honestidad, podrás atestiguar que tu vida se ha convertido en una búsqueda constante.</p>
					<p>En la capa más externa te reconocerás buscando aquello que no tienes y que crees que necesitas para ser feliz. A un nivel más profundo es posible que te veas rechazando aquello que estás sintiendo (incomodidad, inseguridad, miedo...), buscando sentir algo "que te haga sentir mejor".</p>
					<p>El gesto es siempre el mismo, aunque las formas nos hagan creer que somos muy diferentes los unos de los otros. Esta búsqueda, independientemente de su resultado, nos lleva a vivir en una lucha constante con nosotros mismos y con los demás, impidiéndonos amar y vivir en plenitud.</p>
					<p>En Brujuléate llevamos a cabo sesiones individuales de acompañamiento y autoindagación en las que tratar de arrojar luz a todo esta maraña de pensamientos, actitudes y emociones.</p>
					<p>El objetivo es generar consciencia (darse cuenta) de aquello que te está impidiendo honrar y amar tu propia existencia humana.</p>
				</div>
			</div>
			<div className='actions'>
				<Link to={`${location.pathname}/contacto`}>
					<button style={Styles.button}>Contactar con Juan</button>
				</Link>
			</div>
		</div>
	)
}

export default Acompano