import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { FaWhatsapp, FaInstagram, FaEnvelope } from 'react-icons/fa'

const Contact = () => {

	const location = useLocation()

	const closed = () => {
		return location.pathname.split('/').slice(0, -1).join('/')		
	}

	return (
		<div className='modal'>
			<div>
				<p>Puedes contactar con Juan por cualquiera de los siguientes medios:</p>
				<a href="https://wa.me/0034608442010" target="_blank">
					<FaWhatsapp size={42} />
					<p>(+34) 608 442 010</p>
				</a>				
				<a href="https://ig.me/m/brujuleate" target="_blank">
					<FaInstagram size={42} />
					<p>@brujuleate</p>
				</a>
				<a href="mailto:brujuleate@gmail.com">
					<FaEnvelope size={38} />
					<p>brujuleate@gmail.com</p>
				</a>
				<Link to={closed()} className='close'>Cerrar</Link>
			</div>
		</div>
	)
}

export default Contact