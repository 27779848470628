export default class Youtube {

	static getChannelVideos = async (channelId) => {
		return fetch(`${process.env.REACT_APP_YOUTUBE_API_URL}?part=snippet,id&maxResults=50&order=title&channelId=${channelId}&key=${process.env.REACT_APP_YOUTUBE_API_KEY}`)
			.then(response => response.json())
			.then(json => {
				return json.items
					.filter(item => item.id.kind === 'youtube#video')
					.sort((a, b) =>  new Date(a.snippet.publishedAt) - new Date(b.snippet.publishedAt))
			})
	}
}