import React, { useState, useEffect } from 'react'
import { FaPlayCircle } from 'react-icons/fa'
import { Main as Colors } from '../styles/color'
import Youtube from '../../model/Youtube'
import Styles from '../styles/index'
import '../styles/video.scss'

const Videos = () => {

	const [videos, setVideos] = useState([])
	const [selected, setSelected] = useState()

	useEffect(() => {
		Youtube
			.getChannelVideos(process.env.REACT_APP_YOUTUBE_CHANNEL_ID)
			.then(setVideos)
	}, [])

	useEffect(() => {
		console.log(selected)
	}, [selected])

	return (
		<div className='section'>
			<h1>Videos</h1>
			{!selected &&
				<div className='playlist'>
					<ul>
						{videos.map(video => (
							<li key={video.etag} onClick={() => setSelected(video.id.videoId)}>
								<img src={video.snippet.thumbnails.medium.url} />
								<label>{video.snippet.title.replaceAll('&quot;', '"')}</label>
								<FaPlayCircle size={32} color={Colors.secondary} />
							</li>
						))}
					</ul>
				</div>
			}
			{selected && <>
				<div className='video'>
					<iframe width="100%" height="100%" src={`https://www.youtube.com/embed/${selected}?autoplay=1`} />
				</div>
				<button
					onClick={() => setSelected()}
					style={{
						...Styles.button,
						alignSelf: 'center',
						margin: 50,
						width: 120
					}}
				>
					Volver
				</button>
			</>}
		</div>
	)
}

export default Videos