import { Main as Colors } from './color'

export const Common = {
	color: Colors.secondary,
	backgroundColor: 'white',
	fontFamily: 'Lato'
}

export const Section = {
	position: 'absolute',
	top: 320,
	left: 20,
	right: 20,
	borderRadius: 10,
	margin: 'auto',
	padding: 40,
	paddingBottom: 50
}