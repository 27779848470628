import React from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import Styles from './view/styles/index'
import Menu from './view/components/Menu'
import Acompano from './view/pages/Acompano'
import Experimenta from './view/pages/Experimenta'
import Videos from './view/pages/Videos'
import Bio from './view/pages/Bio'
import Pie from './view/components/Pie'
import Home from './view/pages/Home'

const App = () => {

	const location = useLocation()

	return (
		<div style={{
			...Styles.common,
			...(location.pathname.indexOf('/contacto') > 0 ? {
				overflow: 'hidden'
			} : {})
		}}>
			<Menu />
			<Routes>
				<Route path='/acompano/*' element={<Acompano />} />
				<Route path='/experimenta/*' element={<Experimenta />} />
				<Route path='/videos/*' element={<Videos />} />
				<Route path='/bio/*' element={<Bio />} />
				<Route path='/*' element={<Home />} />
			</Routes>
			<Pie />
		</div>
	)
}

export default App
