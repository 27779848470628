import * as Page from './page'
import * as Control from './control'

const Styles = {
	common: Page.Common,
	section: Page.Section,
	button: Control.Button,
	input: Control.Input
}

export default Styles