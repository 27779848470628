import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { motion, AnimatePresence } from 'framer-motion'
import background1 from '../assets/backgrounds/experimenta.jpg'
import background2 from '../assets/backgrounds/experimenta-2.jpg'
import background3 from '../assets/backgrounds/experimenta-3.jpg'
import valores from '../assets/vv.jpg'
import Styles from '../styles/index'
import '../styles/section.scss'

const Experimenta = () => {
	const [currentIndex, setCurrentIndex] = useState(0)
	const [direction, setDirection] = useState(null)

	const backgrounds = [
		background1,
		background2,
		background3
	]

	const location = useLocation()

	const slideVariants = {
	  hiddenRight: {
		x: "-15%",
		opacity: 0.5,
		transition: {
			duration: 2
		}
	},
	  hiddenLeft: {
		x: "-15%",
		opacity: 0.5,
		transition: {
			duration: 2
		}
	  },
	  visible: {
		x: "0",
		opacity: 1,
		transition: {
			duration: 2
		}
	  }
	}

	useEffect(() => {
		const interval = setInterval(() => {
			handleNext()
		}, 5000);
		return () => clearInterval(interval)
	}, [])
  
	const handleNext = () => {
	  setDirection("right")
	  setCurrentIndex((prevIndex) =>
		prevIndex + 1 === backgrounds.length ? 0 : prevIndex + 1
	  )
	}

	return (
		<div className='section'>
			<h1>Experimenta</h1>
			<div>
				<AnimatePresence>
					<motion.img
						key={currentIndex}
						src={backgrounds[currentIndex]}
						initial={direction === "right" ? "hiddenRight" : "hiddenLeft"}
						animate="visible"
						exit="exit"
						variants={slideVariants}
						className='background'
					/>
				</AnimatePresence>
				<div className='content'>
					<h1>Limbo: una experiencia cercana a la vida</h1>
					<p>Otra de las formas en las que realizamos acompañamientos es a través de experiencias como Limbo.</p>
					<p>Limbo es un juego que te invita a experimentar y descubrir la relación que tienes con tus pensamientos, emociones y cuerpo físico.</p>
					<p>Tecnología y espiritualidad se unen para generar una experiencia de teatro sensorial a través de la realidad virtual, creando un espacio íntimo en el que fortalecer el arte de vivir en serenidad y armonía para tomar decisiones que te acerquen a tu verdadera esencia.</p>
					<p>El objetivo de Limbo es generar consciencia a través de la experiencia.</p>
					<p>Experiencia otrecida a través de <a href='http://www.valoresvalientes.com' target='_blank'>Valores Valientes</a></p>
					{/* <img src={valores} alt='Valores valientes' className='logo' /> */}
				</div>
			</div>
			<div className='actions'>
				<Link to={`${location.pathname}/contacto`}>
					<button style={Styles.button}>Contactar con Juan</button>
				</Link>
			</div>
		</div>
	)
}

export default Experimenta