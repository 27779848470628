import * as Page from './page'
import { Main as Colors } from './color'

export const Button = {
	cursor: 'pointer',
	color: 'white',
	backgroundColor: Colors.primary,
	fontFamily: Page.Common.fontFamily,
	borderStyle: 'none',
	borderRadius: 6,
	fontWeight: 'bold',
	fontSize: 18,
	padding: 20
}

export const Input = {
	width: '80%',
	padding: 14,
	marginBottom: 24,
	fontFamily: Page.Common.fontFamily,
	fontSize: 20,
	outline: 'none',
	borderStyle: 'solid',
	borderColor: Colors.primary,
	borderRadius: 9
}

export const Checkbox = {
	width: 24,
	height: 24,
	borderRadius: 6,
	marginBottom: 12,
	backgroundColor: Colors.primary,
	opacity: 0.5
}

export const CheckboxCheck = {
	margin: 4,
	width: 16,
	height: 16,
	borderRadius: 5,
	backgroundColor: 'gray'
}

export const CheckboxChecked = {
	opacity: 1
}

export const CheckboxLabel = {
	paddingLeft: 6,
	paddingRight: 32,
	fontFamily: Page.Common.fontFamily,
	fontSize: 20
}