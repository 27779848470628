import React from 'react'
import { FaWhatsapp, FaYoutube, FaInstagram } from 'react-icons/fa'
import '../styles/common.scss'

const Links = () => {

	return (
		<div className='links'>
			<a href="https://wa.me/0034608442010" target="_blank">
				<FaWhatsapp size={32} />
			</a>
			<a href="https://youtube.com/@brujuleate" target="_blank">
				<FaYoutube size={32} />
			</a>
			<a href="https://www.instagram.com/brujuleate" target="_blank">
				<FaInstagram size={32} />
			</a>
		</div>
	)
}

export default Links