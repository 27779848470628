import React from 'react'
import '../styles/common.scss'
import Links from '../components/Links'
import isoLogo from '../assets/isologo.png'

const Pie = () => {

	return (
		<div className='foot'>
			<img src={isoLogo} alt="Brujuléate" />
			Encontrar el camino de vuelta a casa
			<Links />
		</div>
	)
}

export default Pie