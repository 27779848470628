import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import background from '../assets/backgrounds/bio.jpg'
import Styles from '../styles/index'
import '../styles/section.scss'

const Bio = () => {

	const location = useLocation()

	return (
		<div className='section'>
			<h1>Bio</h1>
			<div>
				<img src={background} className='background' alt='Juan' />
				<div className='content'>
					<p>Juan Barco (Madrid, 1991), es un ser humano que dedica cada instante de su vida a indagar y descubrir con pasión y honestidad lo que es vivir en una experiencia humana en el Siglo XXI.</p>
					<p>Este anhelo por descubrir quién es realmente Juan, ha sido y es el motor de su vida.</p>
					<p>Ve cada instante como una invitación que le hace la vida a descubrir quién es ese Ser en todas sus formas: a través del sufrimiento humano, a través de la gratitud, de la rendición, o del propio amor.</p>
					<p>En la actualidad lleva a cabo acompañamientos individuales y de parejas, talleres y charlas en donde invita a otras personas a descubrir quiénes son a través del juego y la autoindagación.</p>
					<p>Su único compromiso es el de entregarse a cada instante de la vida con total honestidad.</p>
				</div>
			</div>
			<div className='quotes'>
				<p>“En los tiempos que corren es un regalo encontrar a una persona como Juan. Es sencillamente, una persona extraordinaria que ha elegido vivir su camino desde la sabiduría de la no necesidad, la autenticidad y la plenitud del presente. Diría que su don nace en la maravillosa capacidad para observar, comprender y escuchar a las personas para acompañarnos a que exploremos como transcender en nuestro propio camino.”</p>
				<p>Javier</p>
				<p>"Juan es lo más parecido a un sabio que he conocido. Es capaz de mirar más allá y darse cuenta de aquello que los demás no vemos. Te escucha sin juicios y desde una profunda calma. Te coge de la mano y te acompaña hasta tus catacumbas más profundas. Es una fuente de vida y amor.”</p>
				<p>Lucía</p>
				<p>"Miro a los ojos a Juan y a través de la conexión que se genera en esa mirada me llega su sencillez, frescura, autenticidad y amor. Le siento entregándose completamente en esa mirada, expresándose desde quién es, desde su Verdad.”</p>
				<p>Esther</p>
			</div>
			<div className='actions'>
				<Link to={`${location.pathname}/contacto`}>
					<button style={Styles.button}>Contactar con Juan</button>
				</Link>
			</div>
		</div>
	)
}

export default Bio