import React, { useState, useEffect } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import background from '../assets/backgrounds/home.jpg'
import '../styles/carousel.scss'
import '../styles/home.scss'

const Home = () => {
	const [currentIndex, setCurrentIndex] = useState(0)
	const [direction, setDirection] = useState(null)

	const claims = [
		'Brujuléate es una invitación a indagar(te) con amor y honestidad',
		'Brujuléate es una invitación a parar y atender aquello que te está haciendo sufrir',
		'Brujuléate es una invitación a descubrir y honrar el momento presente'
	]
  
	const slideVariants = {
	  hiddenRight: {
		x: "-100%",
		opacity: 0,
	  },
	  hiddenLeft: {
		x: "100%",
		opacity: 0,
	  },
	  visible: {
		x: "50%",
		opacity: 1,
		transition: {
		  duration: 1,
		},
	  },
	}

	const dotsVariants = {
	  initial: {
		y: 0,
	  },
	  animate: {
		scale: 1.2,
		transition: { type: "spring", stiffness: 1000, damping: "10" },
	  },
	  hover: {
		scale: 1.1,
		transition: { duration: 0.2 },
	  },
	}

	useEffect(() => {
		const interval = setInterval(() => {
			handleNext()
		}, 5000);
		return () => clearInterval(interval)
	}, [])
  
	const handleNext = () => {
	  setDirection("right")
	  setCurrentIndex((prevIndex) =>
		prevIndex + 1 === claims.length ? 0 : prevIndex + 1
	  )
	}
  
	const handleDotClick = (index) => {
	  setDirection(index > currentIndex ? "right" : "left")
	  setCurrentIndex(index)
	}
  
	return (
		<div className='home'>
			<img
				src={background}
				className='background'
				alt='Juan'
			/>
			<div className='claim'>
				<AnimatePresence>
					<motion.p
						key={currentIndex}
						src={claims[currentIndex]}
						initial={direction === "right" ? "hiddenRight" : "hiddenLeft"}
						animate="visible"
						exit="exit"
						variants={slideVariants}
					>
						&quot;{claims[currentIndex]}&quot;
					</motion.p>
				</AnimatePresence>
			</div>
			<div className='carousel-indicator'>
				{claims.map((_, index) => (
					<motion.div
						key={index}
						className={`dot ${currentIndex === index ? "active" : ""}`}
						onClick={() => handleDotClick(index)}
						initial="initial"
						animate={currentIndex === index ? "animate" : ""}
						whileHover="hover"
						variants={dotsVariants}
					></motion.div>
				))}
			</div>
		</div>
	)
}

export default Home